import './index.scss';

const Buttons = (): JSX.Element => {
  return (
    <>

    </>
  );
}

export default Buttons;
